<template>
  <!-- App main wrapper -->
  <!-- Comment to test amplify auto-deploy-->
  <div class="qrsconfig">
    <!-- App main content -->
    <main id="main" :class="{
      'main-padding': this.$route.meta.showSidebar,
      'dev-padding': !isProd  
    }">
      <!-- Route Header -->
      <navigation-header v-if="this.$route.meta.requiresAuth" />
      <!-- Vou router wrapper -->
      <transition name="slide-left" mode="out-in">
        <router-view
          :isProd="isProd"
        />
      </transition>
      <!-- Version -->
      <div id="version" :class="{ 'dev-version': !isProd, 'version-p-left': !this.$route.meta.requiresAuth }">
        <p class="m-0">Dashboard Configurador v{{ APPLICATION_VERSION }}</p>
      </div>
      <!-- DevWarning -->
      <dev-warning v-if="!isProd" />
    </main>
    <!-- App sidebar -->
    <sidebar />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { LOCALSTORAGE_KEY_LOGIN } from './lib/Authentication'

export default {
  name: 'App',
  beforeMount() {
    // Forçando https em produção
    if (this.isProd && window.location.protocol !== 'https:')
      return window.location.href = window.location.href.replace('http', 'https')
    // Verificando se usuário digitou senha de acesso
    if (!localStorage.getItem(LOCALSTORAGE_KEY_LOGIN)) {
      return this.$router.push({ name: 'authentication' })
    } else {
      // Iniciando dashboard
      this.token = this.isProd ? process.env.WS_TOKEN : process.env.WS_TEST_TOKEN
      this.wsUrl = this.isProd ? process.env.WS_URL : process.env.WS_TEST_URL
      this.initDashboard()
    }
  },
  computed: {
    isProd() {
      return (location.href.startsWith(process.env.PROD_URL) || location.href.replace('http://', 'https://').startsWith(process.env.PROD_URL))
    }
  },
  data: () => ({
    APPLICATION_VERSION: APPLICATION_VERSION,
    token: null,
    wsUrl: null
  }),
  methods: {
    ...mapActions({
      setAuthentication: 'autenticacao/setAuthentication'
    }),
    initDashboard() {
      this.setAuthentication({
        token: this.token,
        baseUrl: this.wsUrl
      })
    }
  }
};
</script>

<style lang="scss">
@import "./assets/styles/main";
</style>
